/* eslint-disable */
import React, { Component } from 'react';
import $ from 'jquery';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'
import { apiUrl, cmscontentURL} from'../Config/Config';
import { lang } from '../Helpers/lang';
import Select from 'react-select';
import axios from 'axios';
import { scrollToTopValidate, PageTitle,scrollToTop } from "../Helpers/SettingHelper";
import Addinputsimple from './Addinputsimple';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import queryString from "query-string";
class Setting extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			settings_id:'',
			settings_site_title:'',
			settings_from_name:'',
			settings_admin_email:'',
			settings_from_email:'',
			settings_mailpath:'',
			settings_company_address:'',
			settings_commission_percentage:'',
			settings_smtp_host:'',
			settings_smtp_user:'',
			settings_smtp_pass:'',
			settings_smtp_port:'',
			settings_username:'',
			settings_mobileno:'',
			settings_email_footer:'',
			settings_commision_amount:'',
			smtp_checked:false,
			Loading: false,
			drLoading: false,
			displayColorPicker: false,
		
			color: {
			r: '241',
			g: '112',
			b: '19',
			a: '1',
			},
			hexColor:'#f17013',
			settings_public_key:'',
			settings_product_key:'',
			settings_secret_key:'',
			stripe_live_checked:false,
			stripe_sandbox_checked:false,
			settings_embedcode:'',
			setting_goal_amount:'',
			settings_access_code:'',
			st_position_left:'',
			st_position_right:'',
			st_position_top:'',
			st_position_bottom:'',
			domainSets:'',
			removeID:[
        	],
			fonttype : [],
			selectedFont:'',
			fontvalue:'',
			st_position_checked:true,
			st_position:'',
			supporter_additional_fee:'',
			product_layout2_checked:true,
			product_layout3_checked:false,
			google_analystic_code:'',
			category_icons_yes:true,
			category_icons_no:false,
			header_cat_limit:1,
			domain_count:'',
			faqmodelguest: '',
			faqmodeluser: '',
			faqmodelcompany:'',
			faqmodellux:'',
			selectedfaqmodeluserval:'',
			selectedfaqmodelguestval:'',
			selectedfaqmodelcompanyval:'',
			selectedfaqmodelluxval:'',
			selectedfaqmodelguest:'',
			selectedfaqmodelcompany:'',
			selectedfaqmodellux:'',
			selectedfaqmodeluserval:'',
			ai_fine_tune_model_id:'',
			aichat_type:"text",
			np_theme:'',
			selectedplugingwidth:"",
			selectedplugingval:'',
		};
		var qs = require('qs');
		var admin_id = localStorage.getItem('admin_id');
		var postobject = {
			admin_id:  admin_id,
			
		};

		
		axios.post(apiUrl+"adminpanel/adminsettings",qs.stringify(postobject)).then(res => {
			if(res.data.results){
				const formpayload = res.data.results;
				this.setState({settings_id:formpayload.settings_id});
    			this.setState({settings_site_title:formpayload.settings_site_title});
    			this.setState({settings_from_name:formpayload.settings_from_name});
    			this.setState({settings_admin_email:formpayload.settings_admin_email});
    			this.setState({settings_from_email:formpayload.settings_from_email});
				this.setState({settings_notification_email:formpayload.settings_notification_email});
    			this.setState({settings_mailpath:formpayload.settings_mailpath});
    			this.setState({settings_company_address:formpayload.settings_company_address});
    			this.setState({settings_commission_percentage:formpayload.settings_commission_percentage});
    			this.setState({settings_smtp_host:formpayload.settings_smtp_host});
    			this.setState({settings_smtp_user:formpayload.settings_smtp_user});
    			this.setState({settings_smtp_pass:formpayload.settings_smtp_pass});
    			this.setState({settings_smtp_port:formpayload.settings_smtp_port});
    			this.setState({settings_username:formpayload.settings_username});
    			this.setState({settings_mobileno:formpayload.settings_mobileno});
    			this.setState({settings_email_footer:formpayload.settings_email_footer});
    			this.setState({hexColor:formpayload.settings_site_background_color});
    			this.setState({settings_public_key:formpayload.settings_public_key});
				this.setState({settings_product_key:formpayload.settings_product_key});
				this.setState({setting_goal_amount:formpayload.setting_goal_amount});
				this.setState({supporter_additional_fee:formpayload.supporter_additional_fee});
    			this.setState({settings_secret_key:formpayload.settings_secret_key});
				this.setState({settings_embedcode:formpayload.settings_embedcode});
				this.setState({settings_access_code:formpayload.access_code});				
				this.setState({st_position_left:formpayload.st_position_left});
				this.setState({st_position_right:formpayload.st_position_right});
				this.setState({st_position_top:formpayload.st_position_top});
				this.setState({st_position_bottom:formpayload.st_position_bottom});
				this.setState({domainSets : formpayload.domainSets});
				this.setState({settings_commision_amount: formpayload.settings_commision_amount});
				this.setState({header_cat_limit: formpayload.header_cat_limit});
				this.setState({payment_form: formpayload.payment_form,popup_theme: formpayload.popup_theme});
				this.setState({dashboardView: formpayload.dashboardView})
				this.setState({chat_api_key: formpayload.chat_api_key})
				
				if( formpayload.google_analystic_code !== 'null'){
					this.setState({google_analystic_code: formpayload.google_analystic_code});
				}else{
					this.setState({google_analystic_code: ''});
				}
				if(formpayload.pluginwidth!== 'null' && formpayload.pluginwidth !== ''){
					this.setState({selectedplugingwidth: {value:formpayload.pluginwidth, label:formpayload.pluginwidth},selectedplugingval:formpayload.pluginwidth });
				}
    			if(formpayload.settings_mail_from_smtp === '1'){
    				this.setState({smtp_checked: true});
    			}else{
    				this.setState({smtp_checked: false});
    			}

    			if(formpayload.enable_live === '1'){
    				this.setState({stripe_live_checked: true,stripe_sandbox_checked:false });
    			}else{
    				this.setState({stripe_sandbox_checked: true,stripe_live_checked:false});
    			}

    			if(formpayload.st_plugin_position === 'fixed'){
    				this.setState({st_position_checked: true });
    			}else{
    				this.setState({st_position_checked: false});
    			}

    			if(formpayload.product_layout === '2'){
    				this.setState({product_layout2_checked: true });
    				this.setState({product_layout3_checked: false});
    			}else{
    				this.setState({product_layout3_checked: true});
    				this.setState({product_layout2_checked: false });
    			}

				if(formpayload.product_detail_page == 'yes'){
					this.setState({product_detail_default: true });
    				this.setState({product_detail_column: false});
				}else{
					this.setState({product_detail_default: false });
    				this.setState({product_detail_column: true});
				}

				if(formpayload.show_category_icons === 'yes'){
    				this.setState({category_icons_yes: true });
    				this.setState({category_icons_no: false});
    			}else{
    				this.setState({category_icons_no: true});
    				this.setState({category_icons_yes: false });
    			}

				
				if(formpayload.show_quantity === 'yes'){
    				this.setState({stock_quantity_yes: true });
    				this.setState({stock_quantity_no: false});
    			}else{
    				this.setState({stock_quantity_no: true});
    				this.setState({stock_quantity_yes: false });
    			}

				if(res.data.font!== '' && res.data.font!== null){
					this.setselectedvalue(res.data.font);
				}

				if(formpayload.settings_faq_guest_model_id!== 'null' && formpayload.settings_faq_guest_model_id !== ''){
					this.setState({selectedfaqmodelguest: {value:formpayload.settings_faq_guest_model_id, label:formpayload.settings_faq_guest_model_id},selectedfaqmodelguestval:formpayload.settings_faq_guest_model_id});
				}
			}
		});

		
		axios.get(apiUrl+'font/getfont_name?admin_id='+admin_id)
			
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						fonttype: res.data.fontlist
					})
				
				}else{
				//console.log("test")
				}
			});
		this.myCallback();
		this.handleInputChange  = this.handleInputChange.bind(this);
		this.onEditorChange = this.onEditorChange.bind( this );
		
    }
	onEditorChange( evt ) {
    	console.log(evt.editor.getData())
    	var callfunc = this;
    	setTimeout(function () {
			callfunc.setState( {
				consent_form_agree: evt.editor.getData()
			});
		},1000);     
    }
   
    handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name === 'settings_mail_from_smtp'){
      	 this.setState({smtp_checked: !this.state.smtp_checked});
      }	
      if(name === 'settings_enable_live'){
      	 this.setState({stripe_live_checked: !this.state.stripe_live_checked});
      	  this.setState({stripe_sandbox_checked: !this.state.stripe_sandbox_checked});
      }	
      if(name === 'settings_enable_sandbox'){
      	 this.setState({stripe_sandbox_checked: !this.state.stripe_sandbox_checked});
      	 this.setState({stripe_live_checked: !this.state.stripe_live_checked});
      }	
      if(name === 'product_layout'){
      	 this.setState({product_layout2_checked: !this.state.product_layout2_checked});
      	 this.setState({product_layout3_checked: !this.state.product_layout3_checked});
      }

	  if(name === 'category_icons'){
		this.setState({category_icons_yes: !this.state.category_icons_yes});
		this.setState({category_icons_no: !this.state.category_icons_no});
     }

	  if(name === 'drchrono_client_id'){
		this.setState({yourclientid: value});
		this.setState({ dr_refresh_token: '' });

     }
	//  if(name === 'cancellation_fees_type_percentage'){
	// 	this.setState({cancellation_fees_type_percentage: !this.state.cancellation_fees_type_percentage});
	// 	this.setState({cancellation_fees_type_flat: !this.state.cancellation_fees_type_flat});
	// }

	// if(name === 'reschedule_fees_type_flat'){
	// 	this.setState({reschedule_fees_type_flat: !this.state.reschedule_fees_type_flat});
	// 	this.setState({reschedule_fees_type_percentage: !this.state.reschedule_fees_type_percentage});
    //  }
	//  if(name === 'reschedule_fees_type_percentage'){
	// 	this.setState({reschedule_fees_type_percentage: !this.state.reschedule_fees_type_percentage});
	// 	this.setState({reschedule_fees_type_flat: !this.state.reschedule_fees_type_flat});
	// }

      if(name === 'st_position'){
      	this.setState({st_position_checked: !this.state.st_position_checked});
      }
    }
	
    componentDidMount() {

		var admin_id =  localStorage.getItem("admin_id");
		axios.get(apiUrl+"adminpanel/getchatmodel?admin_id="+admin_id)
		.then(res => {			
			if(res.data.status == 'success'){
				this.setState({
					faqmodelguest: res.data.faqmodelguest,
					faqmodeluser: res.data.faqmodeluser
				});		
			}
		});

		const queryParameters = new URLSearchParams(window.location.search)
		const client_id = queryParameters.get("client_id");
		const code = queryParameters.get("code");


      document.title = PageTitle('Settings');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
	  let body = document.querySelectorAll("body")[0];
	if(localStorage.getItem('admin_id')){
		body.classList.remove("body-theme");
	}else{
		body.classList.add("body-theme");
	}
     }
	 
	
    handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				
				const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };
				  
				const formPayload = this.state;

				var qs = require('qs');
				if(this.state.smtp_checked === true){
					var settings_mail_from_smtp = '1';
				}else{
					 settings_mail_from_smtp = '0';
				}
				if(this.state.stripe_live_checked === true){
					var settings_enable_live = '1';
				}else{
					 settings_enable_live = '0';
				}

				if(this.state.st_position_checked === true){
					var plugin_position = 'fixed';
				}else{
					 plugin_position = 'absolute';
				}
                
				var fonttype;
				if(formPayload.selectedFont[0]==undefined){
					fonttype = formPayload.selectedFont.value;
				}else{
					fonttype = formPayload.selectedFont[0].value;
				}
				var product_layout = '';
				if(this.state.product_layout3_checked === true){
					 product_layout = '3';
				}else{
					 product_layout = '2';
				}

				var product_detail = '';
				if(this.state.product_detail_default === true){
					product_detail = 'yes';
				}else{
					product_detail = 'no';
				}

				var show_category = '';
				if(this.state.category_icons_yes === true){
					show_category = 'yes';
				}else{
					show_category = 'no';
				}
				
				var show_quantity = '';
				if(this.state.stock_quantity_yes === true){
					show_quantity = 'yes';
				}else{
					show_quantity = 'no';
				}
				
					
				var postObject = {
					settings_id: formPayload.settings_id,
					settings_site_title:formPayload.settings_site_title,
					settings_from_name:formPayload.settings_from_name,
					settings_admin_email:formPayload.settings_admin_email,
					settings_from_email:formPayload.settings_from_email,
					settings_notification_email:formPayload.settings_notification_email,
					settings_mailpath:formPayload.settings_mailpath,
					settings_company_address:formPayload.settings_company_address,
					settings_commission_percentage:formPayload.settings_commission_percentage,
					site_background: formPayload.hexColor,
					smtp_host:formPayload.settings_smtp_host,
					smtp_user:formPayload.settings_smtp_user,
					smtp_pass:formPayload.settings_smtp_pass,
					smtp_port:formPayload.settings_smtp_port,
					admin_user:formPayload.settings_username,
					admin_no:formPayload.settings_mobileno,
					settings_email_footer:formPayload.settings_email_footer,
					settings_mail_from_smtp:settings_mail_from_smtp,
					settings_public_key:formPayload.settings_public_key,
					settings_product_key:formPayload.settings_product_key,
					setting_goal_amount:formPayload.setting_goal_amount,
					supporter_additional_fee:formPayload.supporter_additional_fee,
					settings_secret_key:formPayload.settings_secret_key,
					settings_enable_live:settings_enable_live,
					st_position_left:formPayload.st_position_left,
					st_position_right:formPayload.st_position_right,
					st_position_top:formPayload.st_position_top,
					st_position_bottom:formPayload.st_position_bottom,
					font 		  : fonttype,
					settings_commision_amount: formPayload.settings_commision_amount,
					plugin_position: plugin_position,
					google_analystic_code: formPayload.google_analystic_code,
					product_layout: product_layout,
					removeId: formPayload.removeID,
					show_category_icons: show_category,
					header_cat_limit: formPayload.header_cat_limit,
					product_detail_page: product_detail,
					payment_form: formPayload.payment_form,
					popup_theme: formPayload.popup_theme,
					show_quantity: show_quantity,
					dashboardView: formPayload.dashboardView,
					chat_api_key : formPayload.chat_api_key,
					settings_faq_guest_model_id:formPayload.selectedfaqmodelguestval,
					plugin_width :formPayload.selectedplugingval,

				};
	
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}
			/* 	var domains = formPayload.domainSets
				for(let i in domains){
					formData.append('domainName[]',domains[i]['domain_name'])
				} */
				

				var domains = formPayload.domainSets;

				for(let i in domains){

					if(domains[i]['domain_id'] ==  'undefined'){
						formData.append('domainName['+ i + '][domain_id]','null')		
					}else if(domains[i]['domain_id'] !==  undefined && domains[i]['domain_id'] !==  ''){
						formData.append('domainName['+ i + '][domain_id]',domains[i]['domain_id'])
					}

					formData.append('domainName['+ i + '][domain_name]',domains[i]['domain_name'])
					formData.getAll('domainName');
				}
				axios.post(apiUrl+"adminpanel/settingsave",formData,config).then(res => {
	
					if(res.data.results){
						this.setState({Loading:false});
					        	const formpayload = res.data.results;
								$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
								this.setState({settings_id:formpayload.settings_id});
								this.setState({settings_site_title:formpayload.settings_site_title});
								this.setState({settings_from_name:formpayload.settings_from_name});
								this.setState({settings_admin_email:formpayload.settings_admin_email});
								this.setState({settings_from_email:formpayload.settings_from_email});
								this.setState({settings_mailpath:formpayload.settings_mailpath});
								this.setState({settings_company_address:formpayload.settings_company_address});
								this.setState({settings_commission_percentage:formpayload.settings_commission_percentage});
								this.setState({settings_smtp_host:formpayload.settings_smtp_host});
								this.setState({settings_smtp_user:formpayload.settings_smtp_user});
								this.setState({settings_smtp_pass:formpayload.settings_smtp_pass});
								this.setState({settings_smtp_port:formpayload.settings_smtp_port});
								this.setState({settings_username:formpayload.settings_username});
								this.setState({settings_mobileno:formpayload.settings_mobileno});
								this.setState({settings_email_footer:formpayload.settings_email_footer});
								this.setState({hexColor:formpayload.settings_site_background_color});
								this.setState({settings_public_key:formpayload.settings_public_key});
								this.setState({settings_product_key:formpayload.settings_product_key});
								this.setState({setting_goal_amount:formpayload.setting_goal_amount});
								this.setState({supporter_additional_fee:formpayload.supporter_additional_fee});
								this.setState({settings_secret_key:formpayload.settings_secret_key});
								this.setState({settings_embedcode:formpayload.settings_embedcode});
								this.setState({settings_access_code:formpayload.access_code});
								this.setState({st_position_left:formpayload.st_position_left});
								this.setState({st_position_right:formpayload.st_position_right});
								this.setState({st_position_top:formpayload.st_position_top});
								this.setState({st_position_bottom:formpayload.st_position_bottom});
								this.setState({domainSets: formpayload.domainSets});
								this.setState({settings_commision_amount: formpayload.settings_commision_amount});
								this.setState({merchant_commision_to_broker: formpayload.merchant_commision_to_broker});	
								this.setState({lux_purchase_count: formpayload.lux_purchase_count});																
								this.setState({header_cat_limit: formpayload.header_cat_limit});
								this.setState({domain_count: formpayload.domain_count});
								
								if(formpayload.settings_faq_user_model_id!== 'null' && formpayload.settings_faq_user_model_id !== ''){
									this.setState({selectedfaqmodeluser: {value:formpayload.settings_faq_user_model_id, label:formpayload.settings_faq_user_model_id},selectedfaqmodeluserval:formpayload.settings_faq_user_model_id });
								}
								if(formpayload.settings_faq_guest_model_id!== 'null' && formpayload.settings_faq_guest_model_id !== ''){
									this.setState({selectedfaqmodelguest: {value:formpayload.settings_faq_guest_model_id, label:formpayload.settings_faq_guest_model_id},selectedfaqmodelguestval:formpayload.settings_faq_guest_model_id});
								}
								if(formpayload.settings_faq_company_model_id!== 'null' && formpayload.settings_faq_company_model_id !== ''){
									this.setState({selectedfaqmodelcompany: {value:formpayload.settings_faq_company_model_id, label:formpayload.settings_faq_company_model_id},selectedfaqmodelcompanyval:formpayload.settings_faq_company_model_id });
								}
								if(formpayload.settings_faq_lux_model_id!== 'null' && formpayload.settings_faq_lux_model_id !== ''){
									this.setState({selectedfaqmodellux: {value:formpayload.settings_faq_lux_model_id, label:formpayload.settings_faq_lux_model_id},selectedfaqmodelluxval:formpayload.settings_faq_lux_model_id });
								}
								this.setState({aichat_type: formpayload.ai_model_type});
								this.setState({ai_fine_tune_model_id: formpayload.ai_fine_tune_model_id});
								this.setState({chat_api_key: formpayload.chat_api_key});
								
								if( formpayload.google_analystic_code !== 'null'){
									this.setState({google_analystic_code: formpayload.google_analystic_code});
								}else{
									this.setState({google_analystic_code: ''});
								}

								if(formpayload.settings_mail_from_smtp === '1'){
									this.setState({smtp_checked: true});
								}else{
									this.setState({smtp_checked: false});
								}


								if(formpayload.st_plugin_position === 'fixed'){
									this.setState({st_position_checked: true });
								}else{
									this.setState({st_position_checked: false});
								}

								if(formpayload.product_layout === '2'){
									this.setState({product_layout2_checked: true });
									this.setState({product_layout3_checked: false});
								}else{
									this.setState({product_layout3_checked: true});
									this.setState({product_layout2_checked: false });
								}

								if(formpayload.enable_live === '1'){
									this.setState({stripe_checked: true});
								}else{
									this.setState({stripe_checked: false});
								}

								if(formpayload.show_category_icons === 'yes'){
									this.setState({category_icons_yes: true });
									this.setState({category_icons_no: false});
								}else{
									this.setState({category_icons_no: true});
									this.setState({category_icons_yes: false });
								}
									scrollToTop();

									setTimeout(
										function() {
											$('.success_message').html('');
										}
										.bind(this),
										3000
										);
				    }
						 
				 });
			}
	}

	validateForm() {

		const {settings_commision_amount,settings_id,settings_site_title,settings_from_name,settings_admin_email,settings_from_email,settings_company_address,settings_commission_percentage,settings_smtp_host,settings_smtp_user,settings_smtp_pass,settings_smtp_port,settings_username,settings_mobileno,settings_email_footer,settings_public_key,settings_product_key,setting_goal_amount,supporter_additional_fee,settings_secret_key,settings_embedcode,selectedFont,
			payment_form,domainSets
		} = this.state;
		const nameRegex = /^[A-Za-z\s.]+$/;
		let errors = 0;
      	//let formIsValid = true;
		if (!settings_site_title) { 
			errors++;
			$('.errorsettings_site_title').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_site_title){
			if (!nameRegex.test(settings_site_title)) {
				errors++;
			$('.errorsettings_site_title').html('<span class="errorspan">Please enter the valid site name</span>');
			}else{
				$('.errorsettings_site_title').html('');
			}
			
		}
		if (!domainSets.length>0) { 
			
			errors++;
			$('.errorsettings_domain').html('<span class="errorspan">Please add atleast one domain</span>');
		}else{		
			$('.errorsettings_domain').html('');
		}
		if (!settings_from_name) { 
			errors++;
			$('.errorsettings_from_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_from_name){
			if (!nameRegex.test(settings_from_name)) {
				errors++;
			$('.errorsettings_from_name').html('<span class="errorspan">Please enter the valid from name</span>');
			}else{
				$('.errorsettings_from_name').html('');
			}
			
		}

		if (!settings_admin_email) {
			errors++; 
			$('.errorsettings_admin_email').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_admin_email){
			var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
			if (reg.test(settings_admin_email)) 
			{
			  $('.errorsettings_admin_email').html('');
			}else{
			  errors++;
			  
			$('.errorsettings_admin_email').html('<span class="errorspan">Please enter valid email address</span>');
			}
			  
			
			}
		if (!settings_from_email) {
			errors++; 
			$('.errorsettings_from_email').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_from_email){
			var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
			if (reg.test(settings_from_email)) 
			{
			  $('.errorsettings_from_email').html('');
			}else{
			  errors++;
			  
			$('.errorsettings_from_email').html('<span class="errorspan">Please enter valid email address</span>');
			}
			  
			
			}
		

		if (!settings_mobileno) { 
			errors++;
			$('.errorsettings_mobileno').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_mobileno){	
			var pattern = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			var indiapattern =/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
			   if (pattern.test(settings_mobileno)) {
			   $('.errorsettings_mobileno').html('');
			   }else if(indiapattern.test(settings_mobileno)){
			   $('.errorsettings_mobileno').html('');
			   }else{
					errors++;
			   
					$('.errorsettings_mobileno').html('<span class="errorspan">Please enter valid contact</span>');
				  }
			   }


		

		if (!settings_email_footer) { 
			errors++;
			$('.errorsettings_email_footer').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_company_address){
			$('.errorsettings_email_footer').html('');
		}
		if (!settings_public_key) {
			errors++;
			$('.errorsettings_public_key').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_public_key){
			$('.errorsettings_public_key').html('');
		}
		
		// if (!settings_product_key) {
		// 	errors++;
		// 	$('.errorsettings_product_key').html('<span class="errorspan">Please fill the field</span>');
		// }else if(settings_product_key){
		// 	$('.errorsettings_product_key').html('');
		// }
		
		if (!settings_secret_key) {
			errors++;
			$('.errorsettings_secret_key').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_secret_key){
			$('.errorsettings_secret_key').html('');
		}
		

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}
    }

    componentWillReceiveProps(Props){
		

    	  	if(Props.updatesetting !== this.props.updatesetting){ 
			
    		if(Object.keys(Props.updatesetting).length > 0){
				
    			this.setState({ Loading: false });

    			const formpayload = Props.updatesetting[0].results;
				
    			$('.success_message').html('<div class="status_sucess"><h3>'+ Props.updatesetting[0].message+'</h3></div>');
    			this.setState({settings_id:formpayload.settings_id});
    			this.setState({settings_site_title:formpayload.settings_site_title});
    			this.setState({settings_from_name:formpayload.settings_from_name});
    			this.setState({settings_admin_email:formpayload.settings_admin_email});
    			this.setState({settings_from_email:formpayload.settings_from_email});
    			this.setState({settings_mailpath:formpayload.settings_mailpath});
    			this.setState({settings_company_address:formpayload.settings_company_address});
    			this.setState({settings_commission_percentage:formpayload.settings_commission_percentage});
    			this.setState({settings_smtp_host:formpayload.settings_smtp_host});
    			this.setState({settings_smtp_user:formpayload.settings_smtp_user});
    			this.setState({settings_smtp_pass:formpayload.settings_smtp_pass});
    			this.setState({settings_smtp_port:formpayload.settings_smtp_port});
    			this.setState({settings_username:formpayload.settings_username});
    			this.setState({settings_mobileno:formpayload.settings_mobileno});
    			this.setState({settings_email_footer:formpayload.settings_email_footer});
    			this.setState({hexColor:formpayload.settings_site_background_color});
    			this.setState({settings_public_key:formpayload.settings_public_key});
				this.setState({settings_product_key:formpayload.settings_product_key});
				this.setState({setting_goal_amount:formpayload.setting_goal_amount});
				this.setState({supporter_additional_fee:formpayload.supporter_additional_fee});
    			this.setState({settings_secret_key:formpayload.settings_secret_key});
				this.setState({settings_embedcode:formpayload.settings_embedcode});
				this.setState({settings_access_code:formpayload.access_code});
				this.setState({st_position_left:formpayload.st_position_left});
				this.setState({st_position_right:formpayload.st_position_right});
				this.setState({st_position_top:formpayload.st_position_top});
				this.setState({st_position_bottom:formpayload.st_position_bottom});
				this.setState({domainSets: formpayload.domainSets});
				this.setState({settings_commision_amount: formpayload.settings_commision_amount});
				this.setState({header_cat_limit: formpayload.header_cat_limit});
				this.setState({domain_count: formpayload.domain_count});
				
				
				if( formpayload.google_analystic_code !== 'null'){
					this.setState({google_analystic_code: formpayload.google_analystic_code});
				}else{
					this.setState({google_analystic_code: ''});
				}

    			if(formpayload.settings_mail_from_smtp === '1'){
    				this.setState({smtp_checked: true});
    			}else{
    				this.setState({smtp_checked: false});
    			}


    			if(formpayload.st_plugin_position === 'fixed'){
    				this.setState({st_position_checked: true });
    			}else{
    				this.setState({st_position_checked: false});
    			}

    			if(formpayload.product_layout === '2'){
    				this.setState({product_layout2_checked: true });
    				this.setState({product_layout3_checked: false});
    			}else{
    				this.setState({product_layout3_checked: true});
    				this.setState({product_layout2_checked: false });
    			}

    			if(formpayload.enable_live === '1'){
    				this.setState({stripe_checked: true});
    			}else{
    				this.setState({stripe_checked: false});
    			}

				if(formpayload.show_category_icons === 'yes'){
    				this.setState({category_icons_yes: true });
    				this.setState({category_icons_no: false});
    			}else{
    				this.setState({category_icons_no: true});
    				this.setState({category_icons_yes: false });
    			}
    				scrollToTop();

    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    		}
    	}
    

    	var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'font/getfont_name?admin_id='+admin_id)
			
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						fonttype: res.data.fontlist
					})
				
				}else{
				//console.log("test")
				}
			});

	}

	setselectedvalue(selectsers){
		
	    const fontArray = selectsers.map((fonttype, index) => ({
	     id: index,
	     name: fonttype,
	    }));
    // Dynamically create select list
    let fonts = [];
    fontArray.map(item =>
    fonts.push({ label: item.name.label, value: item.name.value }),
    );
    this.setState({selectedFont : fonts})
  }

	handleClick = () => {
	this.setState({ displayColorPicker: !this.state.displayColorPicker })
	};

	handleClose = () => {
	this.setState({ displayColorPicker: false })
	};

	handleChange = (color) => {
		this.setState({hexColor : color.hex})
		this.setState({ color: color.rgb })
	};

	 copyCodeToClipboard(event){
		event.preventDefault;
		const el = this.textArea
		el.select()
		document.execCommand("copy")
   }


   writeText = () => {
	 
	$('.copied_title').html('<span class=""><h3>Copied</h3></span>');

	//onClick={() =>  navigator.clipboard.writeText(tester)}
	let test1 = this.state.settings_access_code;
	
	let  tester =	'<script src="https://admin.no1.luxury/plugin.js"></script> <div data-client="'+test1+'"  id="lux_container"></div>';
	 navigator.clipboard.writeText(tester);
	setTimeout(
		function() {
			$('.copied_title').html('');
			$('.copied_title').hide();
		}
		.bind(this),
		3000
		);
		
    }

/*    myCallback = (domainsets) => {
   	//console.log(domainsets,'domainsets')
    this.setState({domainSets: domainsets});
  }; */

  

   myCallback = (domainsets,domainID,domain_count) => {
    this.setState({domainSets: domainsets,removeID: domainID,domain_count:domain_count});
   };
   handleChangeFont = selectedFont => {
      this.setState({ selectedFont});
      this.setState({ fontvalue : selectedFont.value });
      if(selectedFont.value === ''){
        //$('.errorfont').html('<span class="errorspan">Please select the font</span>');
      }else{
      	 $('.errorfont').html('');
      }
   }

   handleChangeAimodeluser = selectedfaqmodeluser => {
	this.setState({ selectedfaqmodeluser});
	this.setState({ selectedfaqmodeluserval : selectedfaqmodeluser.value });	
	}
	handleChangeAimodelguest = selectedfaqmodelguest => {
		this.setState({ selectedfaqmodelguest});
		this.setState({ selectedfaqmodelguestval : selectedfaqmodelguest.value });	
	}

	handleChangeAimodelcompany = selectedfaqmodelcompany => {
		this.setState({ selectedfaqmodelcompany});
		this.setState({ selectedfaqmodelcompanyval : selectedfaqmodelcompany.value });	
	}
	handleChangeAimodellux = selectedfaqmodellux => {
		this.setState({ selectedfaqmodellux});
		this.setState({ selectedfaqmodelluxval : selectedfaqmodellux.value });	
	}
	handleInputddashbordview = (event) => {
		this.setState({ dashboardView: event.target.value });
	};
	handleSave = () => {
		this.setState({Loading:true});
		const config = {
			headers: {
			  'Content-Type': 'multipart/form-data'
			}
		  };
		const formPayload = this.state;
		var qs = require('qs');
		var postObject = {
			
			settings_id: formPayload.settings_id,
			admin_id: localStorage.getItem('admin_id'),
			aifeed:this.state.description,
			type : formPayload.type,
			imp_cate : 6
			
		};

		let formData = new FormData();
		for(let k in postObject) {
		formData.append(k, postObject[k]);
		}
	
		
		

		axios.post(apiUrl+"faq/settingaifeedsave",formData,config).then(res => {
			if(res.data.results){
				// this.setState({Loading:false});
				// 		const formpayload = res.data.results;
				// 		$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
				// 		this.setState({settings_id:formpayload.settings_id});
				// 		scrollToTop();
				// 		setTimeout(
				// 			function() {
				// 				$('.success_message').html('');
				// 			}
				// 			.bind(this),
				// 			3000
				// 			);
			}
				 
		 }); 
	
}

handleChangePlugingwidth = selectedplugingwidth => {
	this.setState({ selectedplugingwidth});
	this.setState({ selectedplugingval : selectedplugingwidth.value });	
}
  render() {
	 
  	/* const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `${ this.state.hexColor }`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    }); */
	let test1 = this.state.settings_access_code;
	//let test = Number(this.state.settings_access_code);
	
	const scripttype = '';
	
		/*const tester =	'<!-- Include <div> tag after the script -->\n<script src="http://car-c.uxt.design/plugin.js"></script>\n<div data-client="'+test+'"  id="car_container"></div>';
		*/
		
		
	let  tester =	'<script src="https://admin.no1.luxury/plugin.js"></script> <div data-client="'+test1+'"  id="lux_container"></div>';


	const fontArray = this.state.fonttype.map ((fonttype, index) => ({
	id: index,
	name: fonttype,
	}));
	// Dynamically create select list
	let fonts = [];
	fontArray.map(item =>
	fonts.push({ label: item.name.label, value: item.name.value }),
	);

	const {selectedFont,selectedplugingwidth ,selectedfaqmodeluser,selectedfaqmodelguest,selectedfaqmodellux,selectedfaqmodelcompany} = this.state;

	const style_text = {
	color: 'white',
    backgroundColor: '#000',
    padding: '9px 9px',
    minwidth: '49px',
    height: '37px',
	textalign:'center',
    texttransform: 'capitalize'
}
const styles = reactCSS({
	'default': {
	  color: {
		width: '36px',
		height: '14px',
		borderRadius: '2px',
		background: `${ this.state.hexColor }`,
	  },
	  swatch: {
		padding: '5px',
		background: '#fff',
		borderRadius: '1px',
		boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
		display: 'inline-block',
		cursor: 'pointer',
	  },
	  popover: {
		position: 'absolute',
		zIndex: '2',
	  },
	  cover: {
		position: 'fixed',
		top: '0px',
		right: '0px',
		bottom: '0px',
		left: '0px',
	  },
	},
  });
	if(this.state.cancellation_fees_type == 'flat')
	{
		$('.cancellation_fee').html('Cancellation fees ($):')
		
	}else{
		$('.cancellation_fee').html('Cancellation fees (%):')
	}	
	if(this.state.reschedule_fees_type == 'flat')
	{
		$('.reschedule_fee').html('Reschedule fees ($):')
		
	}else{
		$('.reschedule_fee').html('Reschedule fees (%):')
	}	
	var refresh_token = this.state.dr_refresh_token;
	var yourclientid = this.state.drchrono_client_id;
	if(this.state.drchrono_client_id =='' && this.state.drchrono_client_secret_key =='' ){

		refresh_token = "";

	}

	if(this.state.drchrono_client_id =='' ){


		yourclientid = "YOUR_CLIENT_ID";
	}
	
	console.log(this.state.domain_count,"domain_countdomain_count")
    return (
      <div className="wrapper"> 
 
	  <Header />
	  <MenuSidebar currentpage="setting" />		

	
	<div className="content">	
		<div className="content-wrapper">
		  <div class="content-wrapper-before"></div>
			<div className="content-body setting-main-head leaa-admin-head card">
			<form className="settings" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-wrapper wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Settings</h4>
			</div>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
				{localStorage.getItem('admin_id') == 1 ?<label>Site Name<span class="required" style={{ color: "red" }} >* </span></label>:<label>Company Name<span class="required" style={{ color: "red" }} >* </span></label>}
					
					<input type="text" name="settings_site_title" onChange={this.handleInputChange} className="form-control" value={this.state.settings_site_title} />
					<div className="errorsettings_site_title"></div>
				</div>
				<div className="form-group">					
					<label>From Name<span class="required" style={{ color: "red" }} >* </span></label>
					<input type="text" name="settings_from_name" onChange={this.handleInputChange} className="form-control"value={this.state.settings_from_name} />
					<div className="errorsettings_from_name"></div>
				</div>
				<div className="form-group">
					<label>From Email<span class="required" style={{ color: "red" }} >* </span></label>
					<input type="text" name="settings_from_email"  onChange={this.handleInputChange} className="form-control" value={this.state.settings_from_email} />
					<div className="errorsettings_from_email"></div>
				</div>
			
				

				<div className="default-font">
			  <div className="form-group">
			  <label>Font<span class="required" style={{ color: "red" }} >* </span></label>
				     <Select 
                         options={fonts}  
                         value={selectedFont?selectedFont:{ value: '0', label: 'Select Font Type' }}
                         onChange={this.handleChangeFont}
                         placeholder="Select Font Type" />
					<div className="errorfont"></div>
				</div>
				</div> 
			

				{ localStorage.getItem('admin_id') == 1 ? <div className="form-group site-bg-clr"> 
				<label>Site Background Color: </label>
						<div style={ styles.swatch } onClick={ this.handleClick }>
						<div style={ styles.color } />
						</div>
						{ this.state.displayColorPicker ? <div style={ styles.popover }>
						<div style={ styles.cover } onClick={ this.handleClose }/>
						<SketchPicker color={ this.state.color } onChange={ this.handleChange } />
						</div> : null }
				</div> : '' }
			</div>
			<div className="form-right">

				<div className="form-group">					
				{localStorage.getItem('admin_id') == 1 ? <label>Admin Email<span class="required" style={{ color: "red" }} >* </span></label>:<label>Company Email:</label>} 
					<input type="text" className="form-control" onChange={this.handleInputChange} name="settings_admin_email" value={this.state.settings_admin_email} />
					<div className="errorsettings_admin_email"></div>
				</div>
				
				<div className="form-group">
					<label>Contact<span class="required" style={{ color: "red" }} >* </span></label>
					<input type="text" className="form-control" name="settings_mobileno"  onChange={this.handleInputChange} value={this.state.settings_mobileno}/>
					<div className="errorsettings_mobileno"></div>
				</div>

				<div className="form-group"> 
					<label>Email Footer Content<span class="required" style={{ color: "red" }} >* </span></label>
					<input type="text" className="form-control" name="settings_email_footer" onChange={this.handleInputChange} value={this.state.settings_email_footer}/>
					<div className="errorsettings_email_footer"></div>
				</div>
				
						
				{/* <div className="form-group">
					<label>Payment Form:</label><br></br>
					    <input onChange={this.handleInputChange} type="radio" name="payment_form" checked={this.state.payment_form=='normal'?'chekced':''} value="normal"/>
	                <span>Normal</span>
	                 <input onChange={this.handleInputChange} type="radio"  name="payment_form" checked={this.state.payment_form=='widget'?'chekced':''} value="widget"/>
	                <span>Widget</span>
            	</div>  */}


				{/* { localStorage.getItem('admin_id') == 1 ? <div className="form-group site-bg-clr"> 
				<label>Site Background Color: </label>
						<div style={ styles.swatch } onClick={ this.handleClick }>
						<div style={ styles.color } />
						</div>
						{ this.state.displayColorPicker ? <div style={ styles.popover }>
						<div style={ styles.cover } onClick={ this.handleClose }/>
						<SketchPicker color={ this.state.color } onChange={ this.handleChange } />
						</div> : null }
				</div> : '' } */}

				  
			</div>				
			</div>	
		    <div className="title">
				<h4>Mail Configuration Settings</h4>
			</div> 
			
			 <div className="form-group">
				    <input onChange={this.handleInputChange} type="checkbox"  name="settings_mail_from_smtp" checked={this.state.smtp_checked}/>
	                <span>Send Mail From Smtp</span>
            </div> 

		 <div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Smtp Host:</label>
					<input type="text" className="form-control" name="settings_smtp_host" onChange={this.handleInputChange}  value={(this.state.settings_smtp_host != 'null')?this.state.settings_smtp_host:''}/>
					<div className="errorsettings_smtp_host"></div>
				</div>
				<div className="form-group">					
					<label>Smtp Username:</label>
					<input type="text" className="form-control" name="settings_smtp_user"  onChange={this.handleInputChange}  value={(this.state.settings_smtp_user != 'null')?this.state.settings_smtp_user:''} />
					<div className="errorsettings_smtp_user"></div>
				</div>					
				<div className="form-group">
					<label>Smtp Password:</label>
					<input type="password" className="form-control" name="settings_smtp_pass" onChange={this.handleInputChange}  value={(this.state.settings_smtp_pass != 'null')?this.state.settings_smtp_pass:''} />
				<div className="errorsettings_smtp_pass"></div>
				</div>
			</div>			
			<div className="form-right">
				<div className="form-group">					
					<label>Smtp Port:</label>
					<input type="text" className="form-control"  name="settings_smtp_port" onChange={this.handleInputChange}  value={(this.state.settings_smtp_port != 'null')?this.state.settings_smtp_port:''} />
				<div className="errorsettings_smtp_port"></div>
				</div>
				<div className="form-group"> 
					<label>Mail Path:</label>
					<input type="text" className="form-control" name="settings_mailpath" onChange={this.handleInputChange} placeholder=""  value={(this.state.settings_mailpath != 'null')?this.state.settings_mailpath:''}/>
				</div>
			</div>	
			</div> 
				


				{/* -------------chat ai setting start-------------- */}

			 <div className="title">
				<h4>Chat AI Setting</h4>
		   </div> 

			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Chat AI type: </label>					
				</div>
				<div className="form-group">
					<label>Chat API key</label>					
				</div>
				<div className="form-group">
					<label>Common / Guest Conversation</label>					
				</div>

				

				<div className="form-group">
					<label>User Conversation</label>					
				</div>
				
			</div> 
			<div className="form-right">
			<div className="form-group">						
				<input onChange={this.handleInputChange} type="radio" name="aichat_type" checked={this.state.aichat_type=='text'?'chekced':''} value="text"/>
				<span>Text</span>
					<input onChange={this.handleInputChange} type="radio"  name="aichat_type" checked={this.state.aichat_type=='chat'?'chekced':''} value="chat"/>
				<span>Chat </span>
			</div> 

			
			<div className="form-group">					
				
			<input type="text" className="form-control" name="chat_api_key" onChange={this.handleInputChange}  value={this.state.chat_api_key}/>
					<div className="errorchat_api_key"></div>		
			</div>

			<div className="form-group">					
				
				<Select 
					options={this.state.faqmodelguest}  
					value={selectedfaqmodelguest?selectedfaqmodelguest:{ value: '0', label: 'Select guest ai model' }}
					onChange={this.handleChangeAimodelguest}
					placeholder="Select AI Model ID" 
				/>			
			</div>

			<div className="form-group">					
				<Select 
					options={this.state.faqmodeluser}  
					value={selectedfaqmodeluser?selectedfaqmodeluser:{ value: '0', label: 'Select user ai model' }}
					onChange={this.handleChangeAimodeluser}
					placeholder="Select AI Model ID" 
				/>			
			</div>		


			
			</div>
      	</div> 
		{/* -------------chat ai setting end-------------- */}

		<div className="title">
				<h4>Feed domain data</h4>
		</div>

		<div className="form-group">					
					<label>Type</label>
						   <input type="radio" name='type' value="text" onChange={this.handleInputChange}/> AI Texting
						   <input type="radio" name='type' value="chat" onChange={this.handleInputChange}/> Assistant Chat 
	           </div>  
		
		<div className="form-group">					
						{/* <textarea type="text" name='description' onChange={this.handleInputChange} className='form-control' value={this.state.description} /> */}
						<CKEditor
							editor={ ClassicEditor }
							data={this.state.description}
							onReady={ editor => {
								// You can store the "editor" and use when it is needed.
								console.log( 'Editor is ready to use!', editor );
							} }
							name="aifeed_desc"
							// onChange={this.handleInputChange}
							onChange={ ( event, editor ) => {
								const data = editor.getData();
								this.setState({description: data});
								
							} }
						/>
					<div className="errordescription"></div>
					<button className='ai-feedbtn' onClick={this.handleSave}>AI Feed</button>
				</div>	

		<div className="title">
				<h4>Domain & Embed Code</h4>
			</div> 
		<div className="form-row">
				<div className="form-left">
				<div className="form-group">
						<label>Embed Code<span class="required" style={{ color: "red" }} >* </span></label>
						<p className="right-side-header"  id="embed_code" name="settings_embedcode" value={tester} title="Copy Text" >{tester}</p>
						<br></br>
						<a id='copytext' style={style_text} onClick={this.writeText}   >Copy  </a> <br></br>
						<div><br></br><div className="copied_title"></div></div>
						<div className="errorsettings_embedcode_title"></div>
					</div>
					
					<div className='form-group'>
				     <h4>Google Analytics code </h4>
					 <textarea type="text" name="google_analystic_code" onChange={this.handleInputChange} className="form-control" value={this.state.google_analystic_code} />
					</div>
				</div>	
				<div className="form-right">
				    <div className='form-group'>
					<h4>Domain<span class="required" style={{ color: "red" }} >* </span></h4>
				    <div className="domain-url">
					<Addinputsimple myCallback={this.myCallback} domainname={this.state.domainSets} domain_count = {this.state.domain_count}/>
					</div>
					<div className="errorsettings_domain"></div>
					</div>
				</div>				
			</div>
			 <h5>Plugin Position (Numbers Only)</h5> 
			 <div className="form-row">			
				<div className="form-left">
					<div className="form-group">
						<label>Left : %</label>
						<input type="number" className="form-control" name="st_position_left"  onChange={this.handleInputChange} value={this.state.st_position_left}/>
						<div className="errorsettings_st_position_left"></div>
					</div>
					<div className="form-group">
						<label>Right : %</label>
						<input type="number" className="form-control" name="st_position_right"  onChange={this.handleInputChange} value={this.state.st_position_right}/>
						<div className="errorsettings_st_position_right"></div>
					</div>
					<div className="form-group">
						<label>Position : </label>
						<input onChange={this.handleInputChange} type="checkbox"  name="st_position" checked={this.state.st_position_checked}/>
	                    <span>Fixed</span>
					</div>
					<div className="form-group">
						<label>Show category icons: </label>
					    <input onChange={this.handleInputChange} type="radio" name="category_icons" checked={this.state.category_icons_yes}/>
		                <span>Yes</span>
		                 <input onChange={this.handleInputChange} type="radio"  name="category_icons" checked={this.state.category_icons_no}/>
		                <span>No</span>
 					</div> 
					 <div className="form-group">
						<label>Show Stock Quantity: </label>
					    <input onChange={this.handleInputChange} type="radio" name="stock_quantity" checked={this.state.stock_quantity_yes}/>
		                <span>Yes</span>
		                 <input onChange={this.handleInputChange} type="radio"  name="stock_quantity" checked={this.state.stock_quantity_no}/>
		                <span>No</span>
 					</div> 
					 <div className="form-group">
						<label>Product Detail page: </label>
						<br/>
					    <input onChange={this.handleInputChange} type="radio" name="product_detail" checked={this.state.product_detail_default}/>
		                <span>Row (Default)</span>
						<br/>
		                 <input onChange={this.handleInputChange} type="radio"  name="product_detail" checked={this.state.product_detail_column}/>
		                <span>Column</span>
 					</div> 
					 <div className="form-group">
					<label>Payment Form:</label><br></br>
					    <input onChange={this.handleInputChange} type="radio" name="payment_form" checked={this.state.payment_form=='normal'?'chekced':''} value="normal"/>
	                <span>Normal</span>
	                 <input onChange={this.handleInputChange} type="radio"  name="payment_form" checked={this.state.payment_form=='widget'?'chekced':''} value="widget"/>
	                <span>Widget</span>
            	</div> 
					 
				</div>
				<div className="form-right">
					<div className="form-group">
						<label>Top: %</label>
						<input type="number" className="form-control" name="st_position_top"  onChange={this.handleInputChange} value={this.state.st_position_top}/>
						<div className="errorsettings_st_position_top"></div>
					</div>
					<div className="form-group">
						<label>Bottom: %</label>
						<input type="number" className="form-control" name="st_position_bottom"  onChange={this.handleInputChange} value={this.state.st_position_bottom}/>
						<div className="errorsettings_st_position_bottom"></div>
					</div>
					<div className="form-group">
						<label>Product Layout: </label>
					    <input onChange={this.handleInputChange} type="radio" name="product_layout" checked={this.state.product_layout2_checked}/>
		                <span>2 Layout</span>
		                 <input onChange={this.handleInputChange} type="radio"  name="product_layout" checked={this.state.product_layout3_checked}/>
		                <span>3 Layout</span>
 					</div> 

					 <div className="form-group">
						<label>Header category limit: </label>
						<input type="number" className="form-control" name="header_cat_limit"  onChange={this.handleInputChange} value={this.state.header_cat_limit}/>
						{/* <div className="errorsettings_st_position_bottom"></div> */}
					</div> 
					<div className="form-group">
							<label>Notification Popup Theme: </label>
							<br />
							<input
								onChange={this.handleInputChange}
								type="radio"
								name="popup_theme"
								value="bww"
								checked={this.state.popup_theme === "bww"}
							/>
							<span>Black bg with White text</span>
							<br />
							<input
								onChange={this.handleInputChange}
								type="radio"
								name="popup_theme"
								value="wwb"
								checked={this.state.popup_theme === "wwb"}
							/>
							<span>White bg with Black text</span>
					</div>
					<div className="form-group">					
					 <label>Plugin Width<span class="required" style={{ color: "red" }} >* </span></label>
						<Select 
							  options={lang.common.pluging_width}  
								value={selectedplugingwidth?selectedplugingwidth:{ value: '0', label: 'Select plugin width' }}
								onChange={this.handleChangePlugingwidth}
							
						/>		
					</div>
					<div className="form-group">
					 <label>Dashboard View: </label>
					 <br/>
                    <input 
                        onChange={this.handleInputddashbordview} 
                        type="radio" 
                        name="dashboard_view" 
                        value="grid"
                        checked={this.state.dashboardView === 'grid'} 
                    />
                    <span>Grid</span>
                    <input 
                        onChange={this.handleInputddashbordview} 
                        type="radio" 
                        name="dashboard_view" 
                        value="list"
                        checked={this.state.dashboardView === 'list'} 
                    />
					 <span>List</span>
 					</div>

				</div>
			</div> 
			

		   <div className="title">
				<h4>Stripe Configuration</h4>
		   </div> 
		   <div className="form-group">
				    <input onChange={this.handleInputChange} type="radio" name="settings_enable_live" checked={this.state.stripe_live_checked}/>
	                <span>Live</span>
	                 <input onChange={this.handleInputChange} type="radio"  name="settings_enable_sandbox" checked={this.state.stripe_sandbox_checked}/>
	                <span>SandBox</span>
            </div> 
			<div className="form-row">
			<div className="form-left">
			<div className="form-group">
					<label>Public Key<span class="required" style={{ color: "red" }} >* </span></label>
					<input type="text" className="form-control" name="settings_public_key" onChange={this.handleInputChange}  value={this.state.settings_public_key}/>
					<div className="errorsettings_public_key"></div>
				</div>

				<div className="form-group">
					<label>Product Key</label>
					<input type="text" className="form-control" name="settings_product_key" onChange={this.handleInputChange}  value={this.state.settings_product_key}/>
					<div className="errorsettings_product_key"></div>
					<span>Note : For Recurring payment(Monthly) the product key use.</span>
				</div>
					
			</div>	
			<div className="form-right">
				
			<div className="form-group">					
					<label>Secret Key<span class="required" style={{ color: "red" }} >* </span></label>
					<input type="password" className="form-control" name="settings_secret_key"  onChange={this.handleInputChange}  value={this.state.settings_secret_key} />
					<div className="errorsettings_secret_key"></div>
				</div>		
			</div>	
      	</div>	

		 
			 <div className="btn-group export">	
				
                <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                <span className="load-data">Loading</span> 
                                } Submit
			    </button>	
			</div>

			</div>
			</form>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default Setting;